import React from "react";
import "./activity-add-edit.style.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  IActivity,
  IActivityAttribute,
  IActivityParameter,
  IActivityType,
} from "../../../models/activity";
import { AppState } from "../../../redux/configureStore";
import { AppActions } from "../../../redux/types/app-actions";
import Loading from "../../loading/loading";
import {
  startGetActivityDetails,
  startGetActivityList,
  startGetActivityMetadata,
  startSaveActivity,
} from "./../activity.service";
import IconButton from "../../common/button/icon-button/icon-button.controller";
import {
  IActivityAddEditLinkDispatchProps,
  IActivityAddEditLinkStateProps,
  IActivityAddEditProps,
  IActivityAddEditState,
  IActivityAttributeDetails,
  IActivityParameterDetails,
} from "./models/IActivityAddEdit";
import {
  FontIcon,
  IComboBox,
  IDropdownOption,
  IDropdownStyles,
  Modal,
  TextField,
} from "@fluentui/react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Checkbox from "../../common/checkbox/checkbox.controller";
import Dropdown from "../../common/dropdown/dropdown.controller";
import PrimaryButton from "../../common/button/primary-button/primary-button.controller";
import AgGrid from "../../common/ag-grid/ag-grid.controller";
import { getDeepCopy } from "../../../util/javascript-functions";
import ActivityAddEditView from "./activity-add-edit.view";
import ComboBox from "../../common/combo-box/combo-box.controller";
import {
  contentStyles,
  iconButtonStyles,
} from "../../common/modal/modal.style";

type Props = IActivityAddEditProps &
  IActivityAddEditLinkDispatchProps &
  IActivityAddEditLinkStateProps;

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 280 },
};

class ActivityAddEdit extends React.Component<Props, IActivityAddEditState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      attributeList: [],
      populatedAttributeList: [],
      remainingAttributeList: [],
      parameterList: [],
      populatedParameterList: [],
      remainingParameterList: [],
      activityTypeList: [],
      selectedActivityType: "",
      activityInfo: {
        FiscalYear: this.props.fiscalYear,
        ActivityId: 0,
        ActivityName: "",
        ActivityTypeId: 0,
        ActivityTypeName: "",
        IsCustomActivity: false,
        IsAlt: false,
        DatasetId: 0,
        DatasetName: "",
        DatasetObject: "",
        EntityLakeReferenceId: 0,
        ConnectionId: 0,
        ConnectionName: "",
        IsActive: true,
        LastRunId: "",
        LastRunExecutionStatus: "",
        LastRunDateTimeStamp: new Date(),
        CreatedBy: "",
        CreatedOn: new Date(),
        ModifiedBy: "",
        ModifiedOn: new Date(),
        Attributes: [],
        Parameters: [],
        Dependencies: [],
        ContainedByProcesses: [],
        ContainedByRunTemplates: [],
      },
    };
    this.populateActivityDetailsCallback = this.populateActivityDetailsCallback.bind(
      this
    );
    this.getAttributes = this.getAttributes.bind(this);
    this.getAttributesGrid = this.getAttributesGrid.bind(this);
    this.updateActivtyName = this.updateActivtyName.bind(this);
    this.getAttributeName = this.getAttributeName.bind(this);
    this.getAttributeValue = this.getAttributeValue.bind(this);
    this.deleteAttribute = this.deleteAttribute.bind(this);
    this.getParametersGrid = this.getParametersGrid.bind(this);
    this.getParameterName = this.getParameterName.bind(this);
    this.getParameterValue = this.getParameterValue.bind(this);
    this.getIsConfigurableCheckbox = this.getIsConfigurableCheckbox.bind(this);
    this.deleteParameter = this.deleteParameter.bind(this);
    this.saveActivityCallback = this.saveActivityCallback.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    if (this.props.activityId) {
      this.props.startGetActivityDetails(
        this.props.activityId,
        this.populateActivityDetailsCallback
      );
    }
    this.props.startGetActivityMetadata(this.props.fiscalYear);
  }

  private handleDismiss() {
    this.props.updateAddEditActivityModalVisibility(false);
  }

  private gridCellKeyPressForAttributeGrid = (event: any) => {
    let keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        case "":
          if (!event.data.IsMandatory) {
            this.deleteAttributeValue(event, event.data);
          }
          break;
        default:
          return;
      }
    }
  };

  private populateActivityDetailsCallback() {
    let _attributeList = this.props.activityDetails?.Attributes;
    let _activityTypeDetails = this.props.activityMetadata?.ActivityTypes.find(
      (at: IActivityType) =>
        at.ActivityTypeId === this.props.activityDetails?.ActivityTypeId
    );
    let _attributeDropdownList: IDropdownOption[] = [];
    let _populatedAttributeList: IActivityAttributeDetails[] = [];
    _attributeList!.forEach((attr: IActivityAttribute) => {
      _populatedAttributeList.push({
        ActivityAttributeId: attr.ActivityAttributeId,
        AttributeName: attr.AttributeName,
        ActivityTypeAttributeId: attr.ActivityTypeAttributeId,
        ActivityAttributeValue: attr.ActivityAttributeValue,
        IsMandatory: attr.IsMandatory,
        CreatedBy: attr.CreatedBy,
        CreatedOn: attr.CreatedOn,
        ModifiedBy: attr.ModifiedBy,
        ModifiedOn: attr.ModifiedOn,
        IsPopulated: true,
        IsLastAddedAttribute: false,
      });
    });
    _activityTypeDetails?.Attributes.forEach((attr: IActivityAttribute) => {
      if (!attr.IsMandatory) {
        _attributeDropdownList.push({
          key: attr.ActivityTypeAttributeId,
          text: attr.AttributeName,
        });
      }
    });
    // Add negative ActivityAttributeId
    let _minActivityAttributeId = -1;
    _populatedAttributeList.forEach((attr) => {
      if (
        attr.ActivityAttributeId &&
        attr.ActivityAttributeId <= _minActivityAttributeId
      ) {
        _minActivityAttributeId = attr.ActivityAttributeId! - 1;
      }
    });
    _populatedAttributeList.push({
      ActivityAttributeId: _minActivityAttributeId,
      AttributeName: "",
      ActivityTypeAttributeId: 0,
      ActivityAttributeValue: "",
      IsMandatory: false,
      CreatedBy: "",
      CreatedOn: new Date(),
      ModifiedBy: "",
      ModifiedOn: new Date(),
      IsPopulated: false,
      IsLastAddedAttribute: true,
    });
    let _parameterList = this.props.activityDetails?.Parameters;
    let _populatedParameterList: IActivityParameterDetails[] = [];
    _parameterList!.forEach((param: IActivityParameter) => {
      _populatedParameterList.push({
        ActivityParameterId: param.ActivityParameterId,
        ParameterId: param.ParameterId,
        ParameterName: param.ParameterName,
        MappedParameterName: param.MappedParameterName,
        ParameterValue: param.ParameterValue,
        IsConfigurable: param.IsConfigurable,
        CreatedBy: param.CreatedBy,
        CreatedOn: param.CreatedOn,
        ModifiedBy: param.ModifiedBy,
        ModifiedOn: param.ModifiedOn,
        IsPopulated: true,
        IsLastAddedParameter: false,
      });
    });
    _populatedParameterList.push({
      ActivityParameterId: 0,
      ParameterId: 0,
      ParameterName: "",
      MappedParameterName: "",
      ParameterValue: "",
      IsConfigurable: false,
      CreatedBy: "",
      CreatedOn: new Date(),
      ModifiedBy: "",
      ModifiedOn: new Date(),
      IsPopulated: false,
      IsLastAddedParameter: true,
    });

    this.setState({
      attributeList: _attributeDropdownList,
      activityInfo: this.props.activityDetails!,
      populatedAttributeList: _populatedAttributeList,
      populatedParameterList: _populatedParameterList,
    });
  }

  private updateActivtyName(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    event.defaultPrevented = true;
    let updatedActivityInfo = getDeepCopy(this.state.activityInfo);
    updatedActivityInfo.ActivityName = newValue ? newValue : "";
    this.setState({ activityInfo: updatedActivityInfo });
  }

  private onActivityTypeDropdownValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    event.defaultPrevented = true;
    const onConfirmation = window.confirm(
      "Are you sure you want to change the selected Activity Type? This will result in clearing all Attributes and Parameters populated below(if any)."
    );
    if (onConfirmation) {
      const _activityType = option !== undefined ? option.text : "";
      if (_activityType == "Web API") {
        window.alert("Note: Only the Authentication Type 'None' is supported for Web API activity.");
      }
      let _updatedActivityInfo = getDeepCopy(this.state.activityInfo);
      _updatedActivityInfo.ActivityTypeName = _activityType;
      _updatedActivityInfo.ActivityTypeId = parseInt(
        option?.key.toString()!,
        10
      );
      this.setState({
        activityInfo: _updatedActivityInfo,
      });
      this.populateActivityAttributeList(option!.text);
      this.populateActivityParameterList();
    }
  };

  // ********************* Attribute Grid Event Handlers *************************** //

  private populateActivityAttributeList(activityTypeName: string) {
    // Populate attributeList, populatedAttributeList, insert isMandatory Attributes + 1 extra attribute for Dropdown list if more atributes are present
    let activityType = this.props.activityMetadata?.ActivityTypes.find(
      (a: IActivityType) => a.ActivityTypeName === activityTypeName
    );
    let _attributeList: IDropdownOption[] = [];
    let _populatedAttributeList: IActivityAttributeDetails[] = [];
    let _minActivityAttributeId = -1;
    activityType?.Attributes.forEach((attr: IActivityAttribute) => {
      if (attr.IsMandatory) {
        const _activityAttributeId =
          !attr.ActivityAttributeId || attr.ActivityAttributeId === 0
            ? _minActivityAttributeId
            : attr.ActivityAttributeId;
        if (_activityAttributeId === _minActivityAttributeId) {
          _minActivityAttributeId--;
        }
        _populatedAttributeList.push({
          ActivityAttributeId: _activityAttributeId,
          AttributeName: attr.AttributeName,
          ActivityTypeAttributeId: attr.ActivityTypeAttributeId,
          ActivityAttributeValue: attr.ActivityAttributeValue,
          IsMandatory: attr.IsMandatory,
          CreatedBy: attr.CreatedBy,
          CreatedOn: attr.CreatedOn,
          ModifiedBy: attr.ModifiedBy,
          ModifiedOn: attr.ModifiedOn,
          IsPopulated: false,
          IsLastAddedAttribute: false,
        });
      } else {
        _attributeList.push({
          key: attr.ActivityTypeAttributeId,
          text: attr.AttributeName,
        });
      }
    });
    // Add negative ActivityAttributeId
    _populatedAttributeList.forEach((attr) => {
      if (
        attr.ActivityAttributeId &&
        attr.ActivityAttributeId <= _minActivityAttributeId
      )
        _minActivityAttributeId = attr.ActivityAttributeId! - 1;
    });
    _populatedAttributeList.push({
      ActivityAttributeId: _minActivityAttributeId,
      AttributeName: "",
      ActivityTypeAttributeId: 0,
      ActivityAttributeValue: "",
      IsMandatory: false,
      CreatedBy: "",
      CreatedOn: new Date(),
      ModifiedBy: "",
      ModifiedOn: new Date(),
      IsPopulated: false,
      IsLastAddedAttribute: true,
    });
    this.setState({
      attributeList: _attributeList,
      remainingAttributeList: _attributeList,
      populatedAttributeList: _populatedAttributeList,
    });
  }

  private addActivityAttributeToList() {
    // Populate attributeList, populatedAttributeList, insert isMandatory Attributes + 1 extra attribute for Dropdown list if morearibues
    // let activityType = this.props.activityMetadata?.ActivityTypes.find(
    //   (a: IActivityType) => a.ActivityTypeName === activityTypeName
    // );
    let _populatedAttributeList: IActivityAttributeDetails[] = [];
    this.state.populatedAttributeList.forEach(
      (p: IActivityAttributeDetails) => {
        _populatedAttributeList.push({
          ActivityAttributeId: p.ActivityAttributeId,
          AttributeName: p.AttributeName,
          ActivityTypeAttributeId: p.ActivityTypeAttributeId,
          ActivityAttributeValue: p.ActivityAttributeValue,
          IsMandatory: p.IsMandatory,
          CreatedBy: p.CreatedBy,
          CreatedOn: p.CreatedOn,
          ModifiedBy: p.ModifiedBy,
          ModifiedOn: p.ModifiedOn,
          IsPopulated: true,
          IsLastAddedAttribute: false,
        });
      }
    );
    // Add negative ActivityAttributeId
    let _minActivityAttributeId = -1;
    _populatedAttributeList.forEach((attr) => {
      if (
        attr.ActivityAttributeId &&
        attr.ActivityAttributeId <= _minActivityAttributeId
      )
        _minActivityAttributeId = attr.ActivityAttributeId! - 1;
    });
    _populatedAttributeList.push({
      ActivityAttributeId: _minActivityAttributeId,
      AttributeName: "",
      ActivityTypeAttributeId: 0,
      ActivityAttributeValue: "",
      IsMandatory: false,
      CreatedBy: "",
      CreatedOn: new Date(),
      ModifiedBy: "",
      ModifiedOn: new Date(),
      IsPopulated: false,
      IsLastAddedAttribute: true,
    });
    this.setState({
      populatedAttributeList: _populatedAttributeList,
    });
  }

  private validateAttribute() {
    let _validationMessage = "";
    let _activityAttributes: IActivityAttributeDetails[] = getDeepCopy(
      this.state.populatedAttributeList
    );
    let _newAttribute = _activityAttributes[_activityAttributes.length - 1];
    if (_newAttribute.ActivityTypeAttributeId === 0) {
      _validationMessage =
        "Please select an attribute from the list before adding.";
      return _validationMessage;
    }
    _activityAttributes.splice(_activityAttributes.length - 1, 1);
    _activityAttributes.forEach((attr: IActivityAttributeDetails) => {
      if (
        _newAttribute.AttributeName.toLowerCase() !==
          "entitylakereferencename" &&
        _newAttribute.ActivityTypeAttributeId === attr.ActivityTypeAttributeId
      ) {
        _validationMessage =
          "Attribute already exists. Please select a new attribute from the list.";
        return;
      }
    });
    return _validationMessage;
  }

  private onAddAttributeClick(event: any, data: any) {
    // event.defaultPrevented = true;
    // Check if AttributeValue is populated
    // Add new Dropdown, Remove Value from dropdown list
    let _validationMessage = this.validateAttribute();
    if (_validationMessage === "") {
      this.addActivityAttributeToList();
    } else {
      window.alert(_validationMessage);
    }
  }

  private onAttributeDropdownValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    const _attributeType = option !== undefined ? option.text : "";

    // const _attributeDetails = this.props.activityMetadata?..find(
    //   (param: IActivityParameter) => param.ParameterName === _parameterName
    // );
    let _populatedAttributeList = this.state.populatedAttributeList;
    let __populatedAttributeListLength = _populatedAttributeList.length;
    _populatedAttributeList[
      __populatedAttributeListLength - 1
    ].AttributeName = _attributeType;
    _populatedAttributeList[
      __populatedAttributeListLength - 1
    ].ActivityTypeAttributeId = parseInt(option?.key.toString()!, 10);
    this.setState({
      populatedAttributeList: _populatedAttributeList,
    });
  };

  private getAttributeName(params: any) {
    if (params.data.IsLastAddedAttribute) {
      return (
        <Dropdown
          placeholder="Select Attribute"
          options={this.state.attributeList}
          styles={dropdownStyles}
          onChange={this.onAttributeDropdownValueChange}
          ariaLabel='Select an Attribute'
        />
      );
    }
    if (params.data.IsMandatory) {
      return <div>{params.data.AttributeName}*</div>;
    } else {
      return <div>{params.data.AttributeName}</div>;
    }
  }

  private updateAttributeValue(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | IComboBox>,
    params: any,
    newValue?: string
  ) {
    // Update ActivityAttributeValue for given AttributeName
    event.defaultPrevented = true;
    let _populatedAttributeList = this.state.populatedAttributeList;
    _populatedAttributeList.forEach((p: IActivityAttributeDetails) => {
      if (p.ActivityAttributeId === params.data.ActivityAttributeId) {
        p.ActivityAttributeValue = newValue ? newValue : "";
      }
    });
    this.setState({
      populatedAttributeList: _populatedAttributeList,
    });
  }

  private getAttributeValue(params: any) {
    // Check if Attribute is a connection attribute
    if (params.data.AttributeName.toString().match(/Connection/i)) {
      let _selectedKey: any;
      if (params.data.ActivityAttributeValue) {
        _selectedKey = this.props.connectionList.find(
          (c) => c.text === params.data.ActivityAttributeValue
        );
      }
      return (
        <ComboBox
          options={this.props.connectionList}
          ariaLabel={"ConnectionList"}
          placeholder={"Select a value"}
          defaultSelectedKey={_selectedKey?.key}
          onChange={(event, option) => {
            let newValue = option !== undefined ? option.text : "";
            this.updateAttributeValue(event, params, newValue);
          }}
        />
      );
    } else {
      return (
        <TextField
          id={params.data.AttributeName}
          defaultValue={params.data.ActivityAttributeValue}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) => this.updateAttributeValue(event, params, newValue)}
          ariaLabel={params.data.ActivityAttributeValue || 'Attribute Value'}
        />
      );
    }
  }

  private deleteAttributeValue(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: any
  ) {
    const onConfirmation = window.confirm(
      "Are you sure you want to delete this Activity Attribute?"
    );
    if (onConfirmation) {
      let _populatedAttributeList: IActivityAttributeDetails[] = [];
      this.state.populatedAttributeList.forEach(
        (p: IActivityAttributeDetails) => {
          if (p.ActivityAttributeId !== data.ActivityAttributeId) {
            _populatedAttributeList.push({
              ActivityAttributeId: p.ActivityAttributeId,
              AttributeName: p.AttributeName,
              ActivityTypeAttributeId: p.ActivityTypeAttributeId,
              ActivityAttributeValue: p.ActivityAttributeValue,
              IsMandatory: p.IsMandatory,
              CreatedBy: p.CreatedBy,
              CreatedOn: p.CreatedOn,
              ModifiedBy: p.ModifiedBy,
              ModifiedOn: p.ModifiedOn,
              IsPopulated: p.IsPopulated,
              IsLastAddedAttribute: p.IsLastAddedAttribute,
            });
          }
        }
      );
      // Add negative ActivityAttributeId
      let _minActivityAttributeId = -1;
      _populatedAttributeList.forEach((attr) => {
        if (
          attr.ActivityAttributeId &&
          attr.ActivityAttributeId <= _minActivityAttributeId
        )
          _minActivityAttributeId = attr.ActivityAttributeId! - 1;
      });
      // Case when last added attribute is deleted
      if (data.IsLastAddedAttribute) {
        _populatedAttributeList.push({
          ActivityAttributeId: _minActivityAttributeId,
          AttributeName: "",
          ActivityTypeAttributeId: 0,
          ActivityAttributeValue: "",
          IsMandatory: false,
          CreatedBy: "",
          CreatedOn: new Date(),
          ModifiedBy: "",
          ModifiedOn: new Date(),
          IsPopulated: false,
          IsLastAddedAttribute: true,
        });
      }
      this.setState({
        populatedAttributeList: _populatedAttributeList,
      });
    }
  }

  private deleteAttribute(params: any) {
    if (!params.data.IsMandatory) {
      return (
        <button
          title="Delete Attribute"
          className="add-delete-button"
          onClick={(event) => this.deleteAttributeValue(event, params.data)}
        >
          <FontIcon iconName="Clear"></FontIcon>
        </button>
      );
    } else {
      return <></>;
    }
  }

  private getAttributesColDefinitions() {
    var columnDefs = [
      {
        headerName: "Attribute Name",
        width: 300,
        cellRendererFramework: this.getAttributeName,
      },
      {
        headerName: "Attribute Value",
        cellRendererFramework: this.getAttributeValue,
        width: 250,
      },
      {
        headerName: "",
        width: 50,
        cellRendererFramework: this.deleteAttribute,
      },
    ];
    return columnDefs;
  }

  private getAttributesGrid() {
    let attributesColDefinition = this.getAttributesColDefinitions();
    let attributesRowData = this.state.populatedAttributeList;
    let _agGridParameters = {
      columnDefs: attributesColDefinition,
      rowData: attributesRowData,
      enableRangeSelection: true,
      rowHeight: 40,
      onCellKeyDown: this.gridCellKeyPressForAttributeGrid,
    };
    return <AgGrid {..._agGridParameters}></AgGrid>;
  }

  private getAttributes() {
    if (
      this.state.populatedAttributeList.length === 0 ||
      this.state.populatedAttributeList === undefined
    ) {
      return <></>;
    } else {
      return (
        <>
          <div>
            <b>Attributes:</b>
          </div>
          <div>
            <PrimaryButton
              text="Add Attribute"
              onClick={(event: any) => this.onAddAttributeClick(event, "")}
            />
          </div>
          <div
            className="ag-theme-balham"
            style={{
              height: "200px",
              width: "100%",
              marginTop: "11px",
            }}
          >
            {this.getAttributesGrid()}
          </div>
          {/* <div>
            <b>Note:</b> The last attribute in the above list will not be added
            as a part of this activity.
          </div> */}
        </>
      );
    }
  }

  // ******************************************************************** //

  // ********************* Parameter Grid Event Handlers *************************** //

  private gridCellKeyPressForParameterGrid = (event: any) => {
    let keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        case "":
          if (!event.data.IsMandatory) {
            this.deleteParameterValue(event, event.data);
          }
          break;
        case "Is Configurable":
          const _isEnabled =
            event.data.MappedParameterName === "" ||
            event.data.MappedParameterName === undefined;
          if (_isEnabled) {
            this.updateIsConfigurable(
              event.data,
              event,
              !event.data.IsConfigurable
            );
          }
          break;
        default:
          return;
      }
    }
  };

  private populateActivityParameterList() {
    // Populate attributeList, populatedAttributeList, insert isMandatory Attributes + 1 extra attribute for Dropdown list if more atributes are present
    let _populatedParameterList: IActivityParameterDetails[] = [];
    _populatedParameterList.push({
      ActivityParameterId: 0,
      ParameterId: 0,
      ParameterName: "",
      MappedParameterName: "",
      ParameterValue: "",
      IsConfigurable: false,
      CreatedBy: "",
      CreatedOn: new Date(),
      ModifiedBy: "",
      ModifiedOn: new Date(),
      IsPopulated: false,
      IsLastAddedParameter: true,
    });
    this.setState({
      populatedParameterList: _populatedParameterList,
    });
  }

  private validateParameter() {
    let _validationMessage = "";
    let _activityParameters = getDeepCopy(this.state.populatedParameterList);
    let _newParameter = _activityParameters[_activityParameters.length - 1];
    if (_newParameter.ParameterId === 0) {
      _validationMessage =
        "Please select a parameter from the list before adding.";
      return _validationMessage;
    }
    _activityParameters.splice(_activityParameters.length - 1, 1);
    _activityParameters.forEach((attr: IActivityParameterDetails) => {
      if (_newParameter.ParameterId === attr.ParameterId) {
        _validationMessage =
          "Parameter already exists. Please select a new parameter from the list.";
        return;
      }
    });
    return _validationMessage;
  }

  private onAddParameterClick(event: any, data: any) {
    // event.defaultPrevented = true;
    // Check if AttributeValue is populated
    // Add new Dropdown, Remove Value from dropdown list
    let _validationMessage = this.validateParameter();
    if (_validationMessage === "") {
      let _populatedParameterList: IActivityParameterDetails[] = [];
      this.state.populatedParameterList.forEach(
        (param: IActivityParameterDetails) => {
          _populatedParameterList.push({
            ActivityParameterId: param.ActivityParameterId,
            ParameterId: param.ParameterId,
            ParameterName: param.ParameterName,
            MappedParameterName: param.MappedParameterName,
            ParameterValue: param.ParameterValue,
            IsConfigurable: param.IsConfigurable,
            CreatedBy: param.CreatedBy,
            CreatedOn: param.CreatedOn,
            ModifiedBy: param.ModifiedBy,
            ModifiedOn: param.ModifiedOn,
            IsPopulated: param.IsPopulated,
            IsLastAddedParameter: false,
          });
        }
      );
      _populatedParameterList.push({
        ActivityParameterId: 0,
        ParameterId: 0,
        ParameterName: "",
        MappedParameterName: "",
        ParameterValue: "",
        IsConfigurable: false,
        CreatedBy: "",
        CreatedOn: new Date(),
        ModifiedBy: "",
        ModifiedOn: new Date(),
        IsPopulated: false,
        IsLastAddedParameter: true,
      });
      this.setState({
        populatedParameterList: _populatedParameterList,
      });
    } else {
      window.alert(_validationMessage);
    }
  }

  private onParameterDropdownValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    const _parameterName = option !== undefined ? option.text : "";
    const _parameterDetails = this.props.activityMetadata?.Parameters.find(
      (param: IActivityParameter) => param.ParameterName === _parameterName
    );

    let _populatedParameterList: IActivityParameterDetails[] = [];
    this.state.populatedParameterList.forEach(
      (param: IActivityParameterDetails) => {
        _populatedParameterList.push({
          ActivityParameterId: param.ActivityParameterId,
          ParameterId: param.ParameterId,
          ParameterName: param.ParameterName,
          MappedParameterName: param.MappedParameterName,
          ParameterValue: param.ParameterValue,
          IsConfigurable: param.IsConfigurable,
          CreatedBy: param.CreatedBy,
          CreatedOn: param.CreatedOn,
          ModifiedBy: param.ModifiedBy,
          ModifiedOn: param.ModifiedOn,
          IsPopulated: param.IsPopulated,
          IsLastAddedParameter: param.IsLastAddedParameter,
        });
      }
    );

    let _populatedParameterListLength = _populatedParameterList.length;
    _populatedParameterList[
      _populatedParameterListLength - 1
    ].ParameterName = _parameterName;
    _populatedParameterList[
      _populatedParameterListLength - 1
    ].MappedParameterName = _parameterDetails!.MappedParameterName;
    _populatedParameterList[
      _populatedParameterListLength - 1
    ].ParameterId = _parameterDetails!.ParameterId;
    this.setState({
      populatedParameterList: _populatedParameterList,
    });
  };

  private getParameterName(params: any) {
    if (params.data.IsLastAddedParameter) {
      return (
        <Dropdown
          placeholder="Select Parameter"
          defaultSelectedKey={params.data.ParameterId}
          options={this.props.activityParameterList}
          styles={dropdownStyles}
          onChange={this.onParameterDropdownValueChange}
          ariaLabel='Select a Parameter'
        />
      );
    } else {
      return <div>{params.data.ParameterName}</div>;
    }
  }

  private updateParameterValue(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    params: any,
    newValue?: string
  ) {
    // Update ActivityAttributeValue for given AttributeName
    event.defaultPrevented = true;
    let _populatedParameterList = this.state.populatedParameterList;
    _populatedParameterList.forEach((p: IActivityParameterDetails) => {
      if (p.ParameterName === params.data.ParameterName) {
        p.ParameterValue = newValue ? newValue : "";
      }
    });
    this.setState({
      populatedParameterList: _populatedParameterList,
    });
  }

  private getParameterValue(params: any) {
    let _isEnabled =
      params.data.MappedParameterName === "" ||
      params.data.MappedParameterName === undefined;
    return (
      <TextField
        defaultValue={params.data.ParameterValue}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => this.updateParameterValue(event, params, newValue)}
        disabled={!_isEnabled}
        ariaLabel={params.data.ParameterValue || 'Parameter Value'}
      />
    );
  }

  private updateIsConfigurable(
    data: any,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) {
    ev!.defaultPrevented = true;
    let _populatedParameterList: IActivityParameterDetails[] = [];
    this.state.populatedParameterList.forEach(
      (param: IActivityParameterDetails) => {
        let _isConfigurable =
          param.ParameterName === data.ParameterName
            ? checked
            : param.IsConfigurable;
        _populatedParameterList.push({
          ActivityParameterId: param.ActivityParameterId,
          ParameterId: param.ParameterId,
          ParameterName: param.ParameterName,
          MappedParameterName: param.MappedParameterName,
          ParameterValue: param.ParameterValue,
          IsConfigurable: _isConfigurable!,
          CreatedBy: param.CreatedBy,
          CreatedOn: param.CreatedOn,
          ModifiedBy: param.ModifiedBy,
          ModifiedOn: param.ModifiedOn,
          IsPopulated: param.IsPopulated,
          IsLastAddedParameter: param.IsLastAddedParameter,
        });
      }
    );
    this.setState({
      populatedParameterList: _populatedParameterList,
    });
  }

  private getIsConfigurableCheckbox(params: any) {
    let _isEnabled =
      params.data.MappedParameterName === "" ||
      params.data.MappedParameterName === undefined;
    return (
      <div style={{ width: "20%" }}>
        <Checkbox
          key={params.data.ParameterId}
          onChange={(
            ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
            checked?: boolean
          ) => this.updateIsConfigurable(params.data, ev, checked)}
          className="control-style"
          checked={params.data.IsConfigurable}
          disabled={!_isEnabled}
          ariaLabel='Is Configurable'
        />
      </div>
    );
  }

  private deleteParameterValue(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: any
  ) {
    const onConfirmation = window.confirm(
      "Are you sure you want to delete this Activity Parameter?"
    );
    if (onConfirmation) {
      let _populatedParameterList: IActivityParameterDetails[] = [];
      this.state.populatedParameterList.forEach(
        (param: IActivityParameterDetails) => {
          if (param.ParameterName !== data.ParameterName) {
            _populatedParameterList.push({
              ActivityParameterId: param.ActivityParameterId,
              ParameterId: param.ParameterId,
              ParameterName: param.ParameterName,
              MappedParameterName: param.MappedParameterName,
              ParameterValue: param.ParameterValue,
              IsConfigurable: param.IsConfigurable,
              CreatedBy: param.CreatedBy,
              CreatedOn: param.CreatedOn,
              ModifiedBy: param.ModifiedBy,
              ModifiedOn: param.ModifiedOn,
              IsPopulated: param.IsPopulated,
              IsLastAddedParameter: param.IsLastAddedParameter,
            });
          }
        }
      );
      // Case when last added parameter is deleted
      if (data.IsLastAddedParameter) {
        _populatedParameterList.push({
          ActivityParameterId: 0,
          ParameterId: 0,
          ParameterName: "",
          MappedParameterName: "",
          ParameterValue: "",
          IsConfigurable: false,
          CreatedBy: "",
          CreatedOn: new Date(),
          ModifiedBy: "",
          ModifiedOn: new Date(),
          IsPopulated: false,
          IsLastAddedParameter: true,
        });
      }
      this.setState({
        populatedParameterList: _populatedParameterList,
      });
    }
  }

  private deleteParameter(params: any) {
    return (
      <button
        title="Delete Parameter"
        className="add-delete-button"
        onClick={(event) => this.deleteParameterValue(event, params.data)}
      >
        <FontIcon iconName="Clear"></FontIcon>
      </button>
    );
  }

  private getParametersColDefinitions() {
    var columnDefs = [
      {
        headerName: "Parameter Name",
        cellRendererFramework: this.getParameterName,
        width: 300,
      },
      {
        headerName: "Parameter Value",
        cellRendererFramework: this.getParameterValue,
        width: 250,
      },
      {
        headerName: "Mapped Parameter",
        field: "MappedParameterName",
        width: 150,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Is Configurable",
        cellRendererFramework: this.getIsConfigurableCheckbox,
        width: 110,
      },
      {
        headerName: "",
        width: 50,
        cellRendererFramework: this.deleteParameter,
      },
    ];
    return columnDefs;
  }

  private getParametersGrid() {
    if (
      this.state.populatedParameterList.length === 0 ||
      this.state.populatedParameterList === undefined
    ) {
      return <></>;
    } else {
      let parametersColDefinition = this.getParametersColDefinitions();
      let parametersRowData = this.state.populatedParameterList;
      let _agGridParameters = {
        columnDefs: parametersColDefinition,
        rowData: parametersRowData,
        enableRangeSelection: true,
        rowHeight: 40,
        onCellKeyDown: this.gridCellKeyPressForParameterGrid,
      };
      return (
        <>
          <div>
            <b>Parameters:</b>
          </div>
          <div>
            <PrimaryButton
              text="Add Parameter"
              onClick={(event: any) => this.onAddParameterClick(event, "")}
            />
          </div>
          <div
            className="ag-theme-balham"
            style={{
              height: "200px",
              width: "100%",
              marginTop: "11px",
            }}
          >
            <AgGrid {..._agGridParameters}></AgGrid>
          </div>
          {/* <div>
            <b>Note:</b> The last parameter in the above list will not be added
            as a part of this activity.
          </div> */}
        </>
      );
    }
  }

  // ******************************************************************** //

  // ********************* Save and Cancel Event Handlers *************************** //
  private saveActivityCallback() {
    this.props.updateAddEditActivityModalVisibility(false);
    this.props.startGetActivityList(this.props.fiscalYear);
  }

  private validateMandatoryAttributes(
    _activityAttributes: IActivityAttributeDetails[]
  ) {
    let _areMandtoryAttributesValid: boolean = true;
    _activityAttributes.forEach((attr: IActivityAttributeDetails) => {
      if (
        attr.IsMandatory &&
        (!attr.ActivityAttributeValue ||
          attr.ActivityAttributeValue.trim().length === 0)
      ) {
        _areMandtoryAttributesValid = false;
      }
    });
    if (!_areMandtoryAttributesValid) {
      window.alert("Please input all Mandtory Attributes");
    }
    return _areMandtoryAttributesValid;
  }

  private runValidation = (): boolean => {
    let isValidationSucessful: boolean = true;
    let _activityDetails: IActivity = getDeepCopy(this.state.activityInfo);
    if (
      _activityDetails.ActivityName.length > 40 ||
      _activityDetails.ActivityName.length < 3
    ) {
      window.alert("Please limit Activity Name between 3 to 40 characters.");
      isValidationSucessful = false;
    }
    return isValidationSucessful;
  };

  private onSaveActivityClick = (): void => {
    const onSaveConfirmation = window.confirm(
      "Are you sure you want to save the below changes?"
    );
    if (onSaveConfirmation) {
      let _isValidationSucessful = this.runValidation();
      let _activityDetails: IActivity = getDeepCopy(this.state.activityInfo);
      let _activityAttributes: IActivityAttributeDetails[] = getDeepCopy(
        this.state.populatedAttributeList
      );

      // Checking if the last added Attribute is valid
      let _isLastAddedAttributeValid: boolean = false;
      let _lastAddedAttribute =
        _activityAttributes[_activityAttributes.length - 1];
      if (_lastAddedAttribute.ActivityTypeAttributeId !== 0) {
        // Checking if it is a duplicate Attribute
        let _existingLastAttributeIndex = _activityAttributes.findIndex(
          (attr: IActivityAttributeDetails) =>
            attr.AttributeName.toLowerCase() !== "entitylakereferencename" &&
            attr.ActivityTypeAttributeId ===
              _lastAddedAttribute.ActivityTypeAttributeId
        );
        if (
          _existingLastAttributeIndex === _activityAttributes.length - 1 ||
          _existingLastAttributeIndex === -1
        ) {
          _isLastAddedAttributeValid = true;
        }
      }
      // Removing last Attribute incase it is invalid
      if (!_isLastAddedAttributeValid) {
        _activityAttributes.splice(_activityAttributes.length - 1, 1);
      }
      // Updating negative(new) ActivityAttributeId to 0.
      _activityAttributes.forEach((attr) => {
        if (attr.ActivityAttributeId && attr.ActivityAttributeId < 0)
          attr.ActivityAttributeId = 0;
      });
      _activityDetails.Attributes = _activityAttributes;
      let _areMandatoryAttributesValid = this.validateMandatoryAttributes(
        _activityAttributes
      );

      let _activityParameters: IActivityParameterDetails[] = getDeepCopy(
        this.state.populatedParameterList
      );
      // Checking if the last added Parameter is valid
      let _isLastAddedParameterValid: boolean = false;
      let _lastAddedParameter =
        _activityParameters[_activityParameters.length - 1];
      if (_lastAddedParameter.ParameterId !== 0) {
        // Checking if it is a duplicate Parameter
        let _existingLastParameterIndex = _activityParameters.findIndex(
          (param: IActivityParameterDetails) =>
            param.ParameterId === _lastAddedParameter.ParameterId
        );
        if (_existingLastParameterIndex === _activityParameters.length - 1) {
          _isLastAddedParameterValid = true;
        }
      }
      // Removing last Parameter incase it is invalid
      if (!_isLastAddedParameterValid) {
        _activityParameters.splice(_activityParameters.length - 1, 1);
      }
      _activityDetails.Parameters = _activityParameters;
      if (_areMandatoryAttributesValid && _isValidationSucessful) {
        this.props.startSaveActivity(
          _activityDetails,
          this.saveActivityCallback
        );
      }
    }
  };
  private onCancelClick = (): void => {
    this.props.updateAddEditActivityModalVisibility(false);
  };
  // ******************************************************************** //

  public render(): JSX.Element {
    if (
      this.props.activityMetadata !== undefined &&
      this.props.activityMetadata != null
    ) {
      const params = {
        activityInfo: this.state.activityInfo,
        activityTypeList: this.props.activityTypeList,
        updateActivtyName: this.updateActivtyName,
        onActivityTypeDropdownValueChange: this
          .onActivityTypeDropdownValueChange,
        getAttributes: this.getAttributes,
        getParametersGrid: this.getParametersGrid,
        onSaveActivityClick: this.onSaveActivityClick,
        onCancelClick: this.onCancelClick,
      };
      return (
        <Modal
          isOpen={this.props.showModal}
          onDismiss={this.handleDismiss}
          containerClassName={contentStyles.container}
        >
          <div className={contentStyles.header}>
            <span id="preview">
              {this.props.isAddActivity
                ? "Add New Activity"
                : "Edit " + this.props.activityName + " Activity"}
            </span>         
            <IconButton
              styles={iconButtonStyles}
              iconName="Cancel"
              title="Close Modal"
              fontSize={12}
              ariaLabel="Close popup modal"
              onClick={this.handleDismiss}
            />
          </div>
          <div id="children" className={contentStyles.body}>
            <ActivityAddEditView {...params} />
          </div>
        </Modal>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps: IActivityAddEditProps
): IActivityAddEditLinkStateProps => {
  return {
    activityDetails: state.activity.activityDetails,
    activityMetadata: state.activity.activityMetadata,
    activityTypeList: state.activity.activityTypeList,
    connectionList: state.connectionState.connectionList,
    activityParameterList: state.activity.activityParameterList,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IActivityAddEditProps
): IActivityAddEditLinkDispatchProps => ({
  startGetActivityDetails: bindActionCreators(
    startGetActivityDetails,
    dispatch
  ),
  startGetActivityMetadata: bindActionCreators(
    startGetActivityMetadata,
    dispatch
  ),
  startSaveActivity: bindActionCreators(startSaveActivity, dispatch),
  startGetActivityList: bindActionCreators(startGetActivityList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityAddEdit);
