import React, { useState } from "react";
import "./side-nav-bar.scss";
import { initializeIcons } from "@fluentui/react";
import SideNavHeader from "./side-nav-bar-nodes-view/base-nodes/side-nav-bar-header";
import sideNavBarHeaderDetails from "./nav-bar-data/header-details";
import SideNavBarNodes from "./side-nav-bar-nodes";
import { connect } from "react-redux";
import { AppState } from "../../../redux/configureStore";

initializeIcons();

const SideNavBar: React.FunctionComponent = (props: any) => {
  const [hover, setHover] = useState(false);
  const sideNavPanel = () => {
    var style = "main-menu main-menu-expand";
    if (props.isSideNavOpen) {
      style = "main-menu main-menu-collapse";
      return(
        <nav id="nav-panel" className={style} tabIndex={-1}>
        <SideNavHeader />
        <SideNavBarNodes {...props} />
       
    
        <div style={{
            height: 200,
            display: "flex",
            position: "relative",
          }}>
      
      <div>
        <img
              src={sideNavBarHeaderDetails.privacyLink}
              alt="No image"
              width="100px" 
              height="25px"
              
        />

        <div className='hover-box' onMouseEnter={() => setHover(true)} 
              onMouseLeave={() => setHover(false)}>
          <button
          tabIndex={-1}
          style={{ fontSize:14,
            fontFamily:"Segoe UI",
           paddingTop:3,
           color:"white",
           background:"transparent",
           border:"transparent"
          }}
          onClick={() =>  window.open('http://go.microsoft.com/fwlink/?LinkId=518021','_blank')}
        >
          <u style={{color:'#9EA6AB'}}>Microsoft Data Privacy Notice</u>
        </button>
        {hover && (
                  <div className="hover-content">
                      <small> http://go.microsoft.com/fwlink/?LinkId=518021 </small>
                  </div>
              )
        }

        </div>
      </div>
      </div>
      </nav>)
    } else {
      style = "main-menu main-menu-expand";
      props.node_data.forEach((data: any) => {
        data.navItem.isExpanded = false;
      });
      return(
        <nav id="nav-panel" className={style} tabIndex={-1}>
        <SideNavHeader />
        <SideNavBarNodes {...props} />
        </nav>
      )
    }

 
  };

  return <div className="side-nav-panel">{sideNavPanel()}</div>;
};

const mapStateToProps = (state: AppState) => {
  return {
    isSideNavOpen: state.isSideNavOpen,
  };
};

export default connect(mapStateToProps)(SideNavBar);
